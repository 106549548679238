import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://www.pngkit.com/png/detail/3-36115_new-website-coming-soon-png-new-web-site.png"
          alt="logo"
        />
        <p className="App-link">in the meantime, you can find me here</p>
        <div>
          <a
            className="App-link"
            href="https://www.twitter.com/codewithzu"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://i.imgur.com/WZGlRu6.png" alt="twitter" />
          </a>
          <a
            className="App-link"
            href="https://www.linkedin.com/in/zulays"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://i.imgur.com/ZWIKPeD.png" alt="linkedin" />
          </a>
          <a
            className="App-link"
            href="mailto:zulay@lifefromabackpack.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://i.imgur.com/F9GpJe1.png" alt="email" />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
